import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import {
  breakpoints,
  useLocation,
  getStoredMarket,
  useShoppingCart,
  useAuth,
  getStoredDistributionCenter,
} from '@ecommerce/shared'
import Layout from '../components/Layout'
import PromotionContainer from '../components/PromotionContainer'
import withPageTransition from '../components/withPageTransition'
import { useCatalogHitClick } from '../hooks/useCatalogHitClick'
import secrets from '../config/secrets'
import { PgPageProps } from '../types/PgPages'
import { ClientSideBlock } from '../graphql/contentfulTypes'
import useQuery from '../hooks/useQuery'
import { sendPageViewEvent } from '../utils/events'

const blocksQuery = `query PromotionsPage($slug: String!) {
  pgPageCollection(where: {location: {slug: $slug}, pageType: "promotions"}) {
    items {
      title
      description
      keywords
      blocksCollection {
        items {
          blockType
          sys {
            id
          }
        }
      }
    }
  }
}

`
type BlocksQueryResult = {
  pgPageCollection: {
    items: Array<{
      title: string
      description?: string
      keywords?: string[]
      blocksCollection?: {
        items?: ClientSideBlock[]
      }
    }>
  }
}

const { COUNTRY } = secrets

const WrapperContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 234px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 206px);
  }

  @media (${breakpoints.desktop.min}) and (${breakpoints.desktop.max}) {
    padding: 77px 40px 0;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    min-height: calc(100vh - 257px);
    padding: 77px 120px 0 140px;
  }
`

const Promotion = (props: PgPageProps<{ promotionsId: string }>) => {
  const { location, pageContext, data } = props
  const { productCategories, currentMarket, currentDistributionCenter } = pageContext

  const storedMarket = getStoredMarket() || currentMarket
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'promotionPageView', title: 'Promoción' })
  }, [])

  const { textByCountry } = useLocation()
  const onCatalogHitClick = useCatalogHitClick({ listName: COUNTRY === 'BO' ? 'Ofertas' : 'Promociones' })

  const refinementList = location?.state?.refinementList
  const refinementListSelected = refinementList && Object.keys(refinementList).filter((item) => refinementList[item])

  const { data: queryData, loading } = useQuery<BlocksQueryResult>(blocksQuery, {
    variables: { slug: storedMarket?.slug },
  })

  const clientSideBlocks = queryData?.pgPageCollection?.items?.[0]?.blocksCollection?.items?.map((block) => ({
    blockType: block.blockType,
    contentful_id: block.sys.id,
  }))
  const blocks = !queryData || loading ? data?.allContentfulPgPage?.edges?.[0]?.node?.blocks : clientSideBlocks

  const description = queryData?.pgPageCollection?.items?.[0]?.description
  const keywords = queryData?.pgPageCollection?.items?.[0]?.keywords

  return (
    <Layout
      id="layout"
      title={textByCountry('Promociones', 'Ofertas')}
      pathName="/promotion"
      categories={productCategories}
      description={description}
      keywords={keywords}
    >
      <WrapperContainer>
        <PromotionContainer
          distributionCenter={storedDistributionCenter}
          refinementListSelected={refinementListSelected}
          onHitClick={onCatalogHitClick}
          blocks={blocks ?? []}
          categories={productCategories}
        />
      </WrapperContainer>
    </Layout>
  )
}

export default withPageTransition(Promotion)

export const query = graphql`
  query PromotionsPageQuery {
    allContentfulPgPage(filter: { pageType: { eq: "promotions" } }) {
      edges {
        node {
          title
          description
          keywords
          blocks {
            contentful_id
            blockType
          }
        }
      }
    }
  }
`

import { toCssPrefix, Image } from '@ecommerce/shared'
import React from 'react'
import styled from 'styled-components'
import { FlatLocationProductCategory } from '../../../types/PgPages'

type StyleProps = {
  isHighlighted?: boolean
  useGrayScale?: boolean
}

type Props = React.HTMLAttributes<HTMLDivElement> &
  StyleProps & {
    category: FlatLocationProductCategory
  }

const { cssPrefix, toPrefix, toPrefixArray } = toCssPrefix('CategoryBox__')
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;

  .${cssPrefix} {
    &label {
      margin: 0;
      font-weight: 700;
      font-size: 15px;
      color: ${({ theme }) => theme.colors.platinum80};
    }

    &image {
      width: 70px;
      height: 70px;
      min-height: 70px;
      min-width: 70px;
      margin: 0 auto 8px;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      border: 2px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      display: block;
      line-height: 80px;
      font-size: 12px;

      &.${cssPrefix}is-highlighted {
        border-color: ${({ theme }) => theme.colors.red};
      }

      &.${cssPrefix}is-gray {
        filter: grayscale(1);
      }
    }
  }
`

const CategoryBox = ({ category, className, isHighlighted, useGrayScale, ...props }: Props) => {
  const imageClassName = toPrefixArray(['image', isHighlighted ? 'is-highlighted' : '', useGrayScale ? 'is-gray' : ''])

  return (
    <Wrapper {...props} className={`${cssPrefix} ${className}`}>
      <Image className={imageClassName} src={category.image} alt={category.name} params={{ q: 80 }} />
      <p className={toPrefix('label')}>{category.name}</p>
    </Wrapper>
  )
}

export default CategoryBox

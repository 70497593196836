import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper/core'
import { connectRefinementList } from 'react-instantsearch-dom'
import { RefinementListProvided } from 'react-instantsearch-core'
import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import CategoryBox from './CategoryBox'
import { ContainerProps } from '../types'
import { useAppDispatch } from '../../../store'
import { setRefinementList } from '../../../store/algolia/searchStateSlice'

type OwnProps = {
  categories: ContainerProps['categories']
}
type Props = RefinementListProvided & OwnProps

const { cssPrefix, toPrefix } = toCssPrefix('CategoriesFilter__')

const Wrapper = styled.div`
  margin: 0 25px;
  width: 100%;

  .${cssPrefix} {
    &slide {
      width: 100px;
      margin-right: 10px;

      &:last-child {
        margin-right: 70px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin: 35px 0 0;
  }
`

const CategoriesFilter = ({ categories = [], refine, currentRefinement }: Props) => {
  const dispatch = useAppDispatch()

  const [categoryFilters, setCategoryFilters] = useState<string[]>([])
  const [mainCategories, setMainCategories] = useState<string[]>([])

  const [swiper, setSwiper] = useState<SwiperCore>()

  const onChangeFilters = (names: string[]) => {
    const filters =
      currentRefinement.filter((refinement) => names.includes(refinement)).length > 0
        ? currentRefinement.filter((refinement) => !names.includes(refinement))
        : [...(currentRefinement ?? []), ...names]

    setMainCategories(filters)

    if (typeof refine === 'function') {
      dispatch(setRefinementList({ mainCategoryName: filters }))
      refine(filters)
    }
  }

  useEffect(() => {
    if (categoryFilters.length && typeof swiper?.slideTo === 'function') {
      swiper.slideTo(categories.findIndex((cat) => cat.name === categoryFilters[0]))
    }
  }, [swiper])

  const isSelected = useCallback(
    (name: string) => {
      return categoryFilters.includes(name)
    },
    [categoryFilters],
  )

  useEffect(() => {
    const categoriesData = mainCategories
      .map((refinement) => categories.find((item) => item.mainCategoryNames.includes(refinement)))
      .flat()

    const filters = categoriesData.map((item) => {
      if (item) return item.name
      return ''
    })

    setCategoryFilters([...new Set(filters)])
  }, [mainCategories])

  useEffect(() => {
    setMainCategories(currentRefinement)
  }, [currentRefinement])

  return (
    <Wrapper className={cssPrefix}>
      <Swiper onSwiper={setSwiper} className={toPrefix('slider')} slidesPerView="auto">
        {categories.map((category) => (
          <SwiperSlide className={toPrefix('slide')}>
            <CategoryBox
              className={toPrefix('category-box')}
              isHighlighted={isSelected(category.name)}
              useGrayScale={currentRefinement.length > 0 && !isSelected(category.name)}
              category={category}
              onClick={() => onChangeFilters(category.mainCategoryNames)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default connectRefinementList<Props>(CategoriesFilter)
